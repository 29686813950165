import moment from 'moment';
import React from 'react';
import { Checkbox, Dropdown, Icon, Input, Popup, TableCell, TableRow } from 'semantic-ui-react';
import { CalendarOptions, PricingTypeDropdownValues } from './AddOnConstants';
import './AddOnsSubscriptionConfigurationTableRow.scss';
import { DeleteAddOnButton } from './DeleteAddOnButton';
import { AddOnConfigurationDto, AddOnSubscriptionConfigurationDto } from './types';


export type AddOnsSubscriptionConfigurationTableRowProps = { 
  addOnSubscriptionConfiguration: AddOnSubscriptionConfigurationDto;
  addOns: AddOnConfigurationDto[];
  readOnly?: boolean;
  onChange: (addOn: AddOnSubscriptionConfigurationDto) => void;
  onDelete: (addOn: AddOnSubscriptionConfigurationDto) => void;
}

export function AddOnsSubscriptionConfigurationTableRow(props: AddOnsSubscriptionConfigurationTableRowProps) {
  const { addOnSubscriptionConfiguration, addOns, readOnly } = props;
  
  const addOnDropDownValues = (addOns || []).map(addOn =>({
    key: `subscription-add-on-dropdown-${addOn.addOnConfigurationId}`,
    text: `${addOn.title} (${addOn.code})`,
    value: addOn.addOnConfigurationId,
  }));
  

  const onPropertyChange = (property: keyof AddOnSubscriptionConfigurationDto, value: any) => {
    props.onChange({ ...props.addOnSubscriptionConfiguration, [property]: value });
  }

  const onDelete = () => { 
    props.onDelete(props.addOnSubscriptionConfiguration);
  }

  return (
    <TableRow className="add-ons-subscription-configuration-table-row">
      <TableCell className='add-ons-subscription-configuration-table-row-value add-on-id'>
        {!readOnly && <DeleteAddOnButton onClick={onDelete} /> }
        <Dropdown
          search
          selection
          value={addOnSubscriptionConfiguration.addOnConfigurationId} 
          options={addOnDropDownValues}
          disabled={readOnly}
          onChange={(_, data) => onPropertyChange('addOnConfigurationId', data.value)} />         
      </TableCell>

      <TableCell className='add-ons-subscription-configuration-table-row-value add-on-disabled'>
        <Checkbox
          disabled={readOnly}
          checked={addOnSubscriptionConfiguration.isDisabled ?? false} 
          onChange={(_, data) => onPropertyChange('isDisabled', data.checked)} /> 
      </TableCell>

      <TableCell className='add-ons-subscription-configuration-table-row-value add-on-pricing-type'>
        <Dropdown 
          value={addOnSubscriptionConfiguration.pricingType} 
          options={PricingTypeDropdownValues}
          disabled={readOnly}
          onChange={(_, data) => onPropertyChange('pricingType', data.value)} /> 
      </TableCell>

      <TableCell className='add-ons-subscription-configuration-table-row-value add-on-price'>
        <Input placeholder='Price when billed monthly'
          type='number'
          step='0.01'
          min='0' 
          disabled={readOnly}
          value={addOnSubscriptionConfiguration.monthlyPrice ?? ''} 
          onChange={(_, data) => onPropertyChange('monthlyPrice', data.value)} /> 
      </TableCell>

      <TableCell className='add-ons-subscription-configuration-table-row-value add-on-price'>
        <Input placeholder='Price when billed annually' 
          type='number'
          step='0.01'
          min='0' 
          disabled={readOnly}
          value={addOnSubscriptionConfiguration.yearlyPrice ?? ''} 
          onChange={(_, data) => onPropertyChange('yearlyPrice', data.value)} /> 
      </TableCell>

      <TableCell className='add-ons-subscription-configuration-table-row-value add-on-user-limit'>
        <Input 
          type='number'
          step='1'
          min='0' 
          disabled={readOnly}
          value={addOnSubscriptionConfiguration.userLimit ?? ''} 
          onChange={(_, data) => onPropertyChange('userLimit', data.value)} /> 
      </TableCell>

      <TableCell className='add-ons-subscription-configuration-table-row-value add-on-metadata'>
        <Popup
          on='click' 
          trigger={<Icon size='small' circular name='info' />}
          content= { 
            <div className='add-ons-dates'>
              <div className='add-ons-dates-section'>
                <label>Created Date</label>
                <span>{addOnSubscriptionConfiguration.createdDate == null ? '--' : moment.utc(addOnSubscriptionConfiguration.createdDate).local().calendar(null, CalendarOptions)}</span>
              </div>
              <div className='add-ons-dates-section'>
                <label>Last Updated Date</label>
                <span>{addOnSubscriptionConfiguration.lastUpdatedDate == null ? '--' : moment.utc(addOnSubscriptionConfiguration.lastUpdatedDate).local().calendar(null, CalendarOptions)}</span>
              </div>
            </div>
          }
        />
      </TableCell>
    </TableRow>
  );
}